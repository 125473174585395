const TextBox = ({ error, ...rest }) => {
  const classNames = [
    'w-full',
    'bg-transparent',
    'border-b-3',
    'border-offwhite/50',
    'outline-none',
    'text-xl',
    'p-2.5',
    'transition-colors',
    'duration-300',
    'focus:border-offwhite',
    'placeholder:text-offwhite/30',
    error ? 'border-deepred-default focus:border-deepred-default' : null
  ]

  return (
    <input
      className={classNames.filter(Boolean).join(' ')}
      type='text'
      {...rest}
    />
  )
}

export default TextBox
