import { useEffect, useState } from 'react'

import { PlayerAdder, Button, QuestionSelector } from '../../components'
import questionSets from '../../assets/questions'

const AddPlayers = ({ players, onPlayerAdd, onPlayerRemove, onStartGame, chosenQuestionSet, setChosenQuestionSet }) => {
  const [error, setError] = useState('')

  useEffect(() => {
    if (players.length >= 2) {
      setError('')
    }
  }, [players])

  const startGame = () => {
    if (players.length < 2) {
      setError('Minimum 2 players to start game.')
    } else {
      onStartGame()
    }
  }

  return (
    <div className='flex flex-col h-full w-full'>
      <PlayerAdder players={players} onPlayerAdd={onPlayerAdd} onPlayerRemove={onPlayerRemove} />
      <QuestionSelector questionSets={questionSets} chosenQuestionSet={chosenQuestionSet} setChosenQuestionSet={setChosenQuestionSet} />
      {error && <p className='text-deepred-default font-bold mb-2.5 text-center'>{error}</p>}
      <div className='flex flex-col w-full flex-1 justify-end sm:justify-start mb-2.5'>
        <Button green={players.length >= 2} text='Start Game' onClick={startGame} />
      </div>

      {
        process.env.NODE_ENV !== 'production' &&
          <div style={{ marginTop: 10 }}>
            <Button
              text='Populate Game'
              onClick={() => {
                onPlayerAdd('Alex')
                onPlayerAdd('Em')
                onPlayerAdd('Lukey')
              }}
            />
          </div>
      }
    </div>
  )
}

export default AddPlayers
