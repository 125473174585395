const testQuestions = {
  name: 'Hardcore',
  description: 'Gets to the point',

  questions: {
    individual: {
      0: [
        'Take a drink.',
        'Choose a player to take a drink.',
        'Take 5 sips.'
      ],
      2: [
        'Do a shot.'
      ]
    },
    group: {
      0: [
        'Everyone drink.',
        'Everyone take 6 sips.'
      ],
      4: [
        'Everyone link arms and do a shot.'
      ]
    }
  }
}

export default testQuestions
