import { useCallback, useState } from 'react'

import { AddPlayers, GamePlay } from './containers'
import usePlayers from './hooks/use-players'

const App = () => {
  const { players, addPlayer, removePlayer, resetPlayers, choosePlayer } = usePlayers()
  const [chosenQuestionSet, setChosenQuestionSet] = useState('standard')
  const [gameState, setGameState] = useState('addPlayers')

  /**
   * Move the game into the starting state and reset scores and weights
   */
  const startGame = useCallback(() => {
    resetPlayers()
    setGameState('inGame')
  }, [resetPlayers])

  return (
    <div className='flex flex-col items-center min-h-full min-w-full bg-bluegray-default text-offwhite font-body'>
      <h1 className='mt-5 font-title text-[7.5vw] sm:text-[2em] font-bold text-center'>Alex Gale's Drinking Game</h1>
      <div className='flex flex-col items-center w-full h-full max-w-[402px] mt-5 p-2.5 sm:p-0'>
        {
          {
            addPlayers: <AddPlayers chosenQuestionSet={chosenQuestionSet} setChosenQuestionSet={setChosenQuestionSet} players={players} onPlayerAdd={addPlayer} onPlayerRemove={removePlayer} onStartGame={startGame} />,
            inGame: <GamePlay players={players} choosePlayer={choosePlayer} chosenQuestionSet={chosenQuestionSet} />
          }[gameState]
        }
      </div>
    </div>
  )
}

export default App
