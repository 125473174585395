import { Button, TextBox } from '../'
import Player from './player'
import { useCallback, useEffect, useState } from 'react'

const PlayerAdder = ({ players, onPlayerAdd, onPlayerRemove }) => {
  const [playerName, setPlayerName] = useState('')
  const [textBoxError, setTextBoxError] = useState(false)

  useEffect(() => {
    setPlayerName('')
  }, [players])

  const addPlayer = useCallback(() => {
    if (playerName === '' || playerName.length > 20) {
      setTextBoxError(true)
    } else {
      setTextBoxError(false)
      onPlayerAdd(playerName)
    }
  }, [playerName, onPlayerAdd])

  return (
    <div className='w-full'>
      {/* Player list */}
      <div>
        {
          players.map((player, i) => <Player key={i} id={i} name={player.name} onRemove={onPlayerRemove} />)
        }
      </div>

      <form className='w-full' onSubmit={e => e.preventDefault()}>
        <div className='mb-5'>
          <TextBox
            required
            maxLength={20}
            error={textBoxError}
            placeholder={`Player ${players.length + 1}'s Name`}
            value={playerName}
            onChange={e => setPlayerName(e.target.value)}
          />
        </div>
        <div className='mb-5'>
          <Button submit text='Add Player' onClick={addPlayer} />
        </div>
      </form>
    </div>
  )
}

export default PlayerAdder
