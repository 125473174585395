const Button = ({ text, green, red, disabled, onClick }) => {
  const classNames = [
    'w-full',
    'text-xl',
    'border-offwhite',
    'border-3',
    'p-2.5',
    'rounded-lg',
    'cursor-pointer',
    'select-none',
    'duration-300',
    'opacity-50',
    'hover:opacity-100',
    green ? 'text-mintgreen border-mintgreen' : null,
    red ? 'text-deepred border-deepred' : null,
    disabled ? 'hover:opacity-50 cursor-default' : null
  ]

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames.filter(Boolean).join(' ')}
    >
      {text}
    </button>
  )
}

export default Button
