const questionSetClasses = [
  'question-set',
  'border-3',
  'border-offwhite',
  'duration-300',
  'transition-opacity',
  'select-none',
  'rounded-lg',
  'p-2.5',
  'cursor-pointer',
  'w-full',
  'first:ml-0',
  'last:mr-0',
  'flex-1',
  'mt-1',
  'sm:mx-1',
  'sm:mt-0'
]

const QuestionSelector = ({ questionSets, chosenQuestionSet, setChosenQuestionSet }) => {
  return (
    <div className='flex w-100 mb-1 flex-col sm:flex-row'>
      {Object.keys(questionSets).map((qs, i) => (
        <div
          key={i}
          className={[...questionSetClasses, chosenQuestionSet === qs ? 'opacity-100' : 'opacity-50'].join(' ')}
          onClick={() => setChosenQuestionSet(qs)}
        >
          <p className='font-title font-bold'>{questionSets[qs].name}</p>
          <p className='leading-5'>{questionSets[qs].description}</p>
        </div>
      ))}
    </div>
  )
}

export default QuestionSelector
