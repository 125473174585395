const standardQuestions = {
  name: 'Standard Questions',
  description: 'The original set of questions',
  questions: {
    individual: {
      0: [
        'Take a drink.',
        'Show the last photo in your camera roll or take a drink.',
        'The group will ask you a question. If you can\'t answer it, take a drink.',
        'The group can ask you to perform any dare. If you can\'t do it, take a drink.',
        'Choose a drinking mate. They must drink with you until your name appears again.',
        'The players to your left and right are now a drinking couple. If one of them drinks, the other must also.',
        'Choose someone to steal a drink from.',
        'Choose the player you would most like to swap lives with, then drink together.',
        'Shake hands with the other players. Choose the best handshake and then drink together.',
        'The next player to make eye contact with you must drink.',
        'Every player drinking the same drink as you must drink.',
        'Swap any piece of clothing with {player}.',
        'Guess {player}\'s drink of choice.',
        '{player} is now your drinking mate. Drink together until your name appears again.',
        'Guess what {player}\'s catchphrase is. If everyone agrees, they drink, otherwise you drink.',
        'Swap seats with {player} and drink together.',
        'Play Rock Paper Scissors with {player}. The loser must drink.',
        'Guess {player}\'s date of birth. If you are wrong, you must drink.'
      ],
      3: [
        'Remove an article of clothing, or drink.',
        'Top up your drink, and then drink down to where it was before.',
        'Everyone in the group donates some of their drink to the King\'s cup, then you must drink it.',
        'Play the Suck and Blow game, starting from you.',
        'Perform an impression of someone in the group. Everyone else guesses who it is - if they get it wrong, drink.',
        'Tell a joke – if anyone laughs, they drink. If no one laughs, you drink.',
        'Give Alex some of your drink as a thank you for making this game.',
        'Give Em some of your drink as a thank you for coming up with the questions for this game.',
        'Chug your drink for 10 seconds.',
        'You can only lie until you name appears again. Drink if you are caught telling the truth.',
        'Hold hands with the player to your left until your name appears again.',
        'You must stay silent until your name appears again. If you talk, you must drink.',
        'Swap shirts with {player}.',
        'Tell {player} two truths and one lie. If they can deduce the lie, you drink. If not, they drink.',
        'Give {player} a piggyback around the room.'
      ],
      5: [
        'Finish your drink in 10 seconds. If you can\'t do it, take a shot.',
        'Kiss one player and slap another player (or the same player).',
        'Choose two other players to have a threesome with (you don\'t have to do it).',
        'Act out a sex position of the group’s choice with the player to your left.',
        'Act out a sex position of the group’s choice with the player to your right.',
        'Sit on the {player}\'s lap until your name appears again.',
        'Guess what {player}\'s biggest kink is. If you\'re right, they drink, otherwise you drink.',
        'Take a body shot off {player}.'
      ]
    },

    group: {
      0: [
        'Everyone drinks.',
        'Single players drink.',
        'Taken players drink.',
        'Couples drink.',
        'Oldest player drinks.',
        'Youngest player drinks.',
        'Last player to arrive drinks.',
        'Most likely to drunk cry drinks.',
        'Most likely to blackout drinks.',
        'Drink if you have a job.',
        'Drink if you have never held down a job for more than 6 months.',
        'Drink if you are touching your phone.',
        'Drink if you say you hate TikTok but actually just watch them all on Instagram anyway.',
        'Drink if you miss Miles :\')',
        'Drink if you own an Apple product',
        'Players drinking spirits - do a shot.',
        'Decide which player has the worst birthday. They must drink.',
        'Line up in height order – the median player drinks.',
        'Player controlling music - play a better song then drink.',
        'On the count of three, everyone look up and gaze into another player\'s eyes. Anyone making eye contact must drink.',
        'Play a game of Heads or Tails. Players who pick the wrong side must drink.',
        'Play a round of Would You Rather chosen by {player}. Players in the minority drink.',
        'Play a round of Never Have I Ever chosen by {player} (sorry Luke).'
      ],
      4: [
        'Quietest player drinks.',
        'Loudest player drinks.',
        'Player with the best room drinks.',
        'Most likely to engage in sexual intercourse with their ex drinks.',
        'Drink if "it\'s complicated" or you’re linking someone.',
        'Drink if you’ve had a one night stand.',
        'Drink if you’ve got with someone 5+ years older than you.',
        'Drink if you’ve talked to someone creepy on a dating app – tell the story if so.',
        'Drink if you\'ve ever been friendzoned.',
        'The floor is lava! Last player touching the floor drinks.',
        'Last player to stand on their chair drinks.',
        'Last player to sit on the floor drinks.',
        'Decide who has the best outfit – everyone but them drinks.',
        'Decide who has the best muscles - everyone but them drinks.',
        'Decide who the biggest criminal is - they must drink.',
        'Go around the circle sharing biggest "icks". The player with the stupidest "ick" drinks.',
        'Everyone point at another player. The player with the most fingers pointing at them must drink.',
        'Play a round of Waterfall starting from {player}.'
      ],
      10: [
        'Last player to wank drinks.',
        'Drink if you’ve fancied another player.',
        'Drink if you’ve slept with another player.',
        'Drink if you’ve sent nudes to a stranger.',
        'Drink if there’s a nude in your camera roll.',
        'Take as many sips as your body count.',
        'Everyone get up and sit in a different seat. Drink if you end up in the same place.'
      ]
    }
  }
}

export default standardQuestions
