import { useState } from 'react'
import TextLoop from 'react-text-loop'

import { Button } from '..'
import SlotMachine from '../../assets/images/spinner.png'

const DEFAULT_GROUP_CHANCE = 0.25

const playerSpinnerNameClasses = [
  'text-bluegray-darker',
  'flex',
  'items-center',
  'absolute',
  'bg-offwhite',
  'font-bold',
  'select-none',
  'overflow-hidden',
  'mt-[8%]',
  'text-[9vw]',
  'w-[72%]',
  'h-[45%]',
  'px-[7%]',
  'sm:mt-[9%]',
  'sm:text-[35px]'
]

const PlayerSpinner = ({ playerNames, choosePlayer, onPlayerChosen, onSpin }) => {
  const [chosenPlayerIndex, setChosenPlayerIndex] = useState()
  const [spinning, setSpinning] = useState(false)
  const [spinningVisual, setSpinningVisual] = useState(false)
  const [groupChance, setGroupChance] = useState(DEFAULT_GROUP_CHANCE)

  const spinWheel = () => {
    setSpinning(true)
    setSpinningVisual(true)
    onSpin()

    const chosenPlayer = Math.random() > (1 - groupChance) && chosenPlayerIndex !== -1 ? -1 : choosePlayer()

    // increase group chance every time it is not chosen
    setGroupChance(chosenPlayer === -1 ? DEFAULT_GROUP_CHANCE : groupChance + 0.05)

    setTimeout(() => {
      // stop spinning to place chosen value into TextLoop
      setSpinning(false)

      // set chosen player and pass up index
      setChosenPlayerIndex(chosenPlayer)

      // short wait before actually stopping spinner because TextLoop is fussy about updating children
      setTimeout(() => {
        setSpinningVisual(false)

        // pass up player index
        onPlayerChosen(chosenPlayer)
      }, 110)
    }, 1500)
  }

  return (
    <div className='flex flex-col items-center w-full relative'>
      <div className='flex justify-center'>
        <div className={playerSpinnerNameClasses.join(' ')}>
          <TextLoop interval={spinningVisual ? 100 : 0} springConfig={{ stiffness: 180, damping: 8 }}>
            {
              spinning
                ? [...playerNames, 'Everyone'].map((name, i) => <p key={i} className='font-title'>{name}</p>)
                : chosenPlayerIndex == null
                  ? <p className='font-title'>SPIN</p>
                  : <p className='font-title'>{chosenPlayerIndex >= 0 ? playerNames[chosenPlayerIndex] : 'Everyone'}</p>
            }
          </TextLoop>
        </div>
        <img className='w-[95%] select-none z-10 pointer-events-none' src={SlotMachine} alt='' draggable={false} />
      </div>
      <div className='w-[95%] mt-2.5'>
        <Button disabled={spinningVisual} text='Spin' onClick={spinWheel} />
      </div>
    </div>
  )
}

export default PlayerSpinner
