const Player = ({ id, name, onRemove }) => {
  return (
    <div className='player flex justify-center text-xl border-b-2 border-bluegray-darker mb-2.5 pb-1'>
      <p className='flex-1 ml-[12.67px] text-center'>{name}</p>
      <p className='text-deepred-default hover:text-deepred-darker font-bold select-none cursor-pointer transition-colors duration-300' onClick={() => onRemove(id)}>X</p>
    </div>
  )
}

export default Player
