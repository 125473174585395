const QuestionCard = ({ playerName, challengePlayerName, question, spinning }) => {
  return (
    <div className={`w-[95%] rounded-lg p-2.5 text-bluegray-darker bg-offwhite duration-300 transition-all text-center ${spinning || question === '' ? 'opacity-0 mt-12' : 'opacity-100 mt-5'}`}>
      <div className='font-title font-bold text-2xl border-2 border-b-bluegray-lighter pb-2.5 overflow-hidden text-ellipsis'>{playerName}</div>
      <div className='mt-2.5 text-lg'>{question.replace('{player}', challengePlayerName)}</div>
    </div>
  )
}

export default QuestionCard
