import { useState } from 'react'

const usePlayers = () => {
  const [players, setPlayers] = useState([])

  /**
   * Add player to game
   */
  const addPlayer = (name) => {
    if (name === '') return false

    const newPlayer = { name: name, score: 0, weight: 1 }
    setPlayers(players => [...players, newPlayer])

    return true
  }

  /**
   * Remove player from game
   */
  const removePlayer = (index) => {
    if (index > players.length - 1) return false

    setPlayers(players => [...players].filter((_, i) => i !== index))

    return true
  }

  /**
   * Reset players' scores and weights
   */
  const resetPlayers = () => {
    setPlayers(players => [...players].map(player => {
      return { ...player, score: 0, weight: 1 }
    }))
  }

  /**
   * Weighted random choose a player and adjust weights and scores
   */
  const choosePlayer = (shouldUpdate = true) => {
    // weighted choice generator
    const choiceArray = []
    players.forEach((player, i) => {
      [...Array(Math.floor(player.weight))].forEach(() => {
        choiceArray.push(i)
      })
    })

    const chosenPlayerIndex = choiceArray[Math.floor(Math.random() * choiceArray.length)]

    if (shouldUpdate) {
      setPlayers(players => [...players].map((player, i) => {
        if (i === chosenPlayerIndex) {
          return { ...player, weight: 0, score: player.score + 1 }
        } else {
          return { ...player, weight: player.weight + 1 }
        }
      }))
    }

    return chosenPlayerIndex
  }

  return { players, addPlayer, removePlayer, resetPlayers, choosePlayer }
}

export default usePlayers
