import { useEffect, useState } from 'react'
import logger from 'gale-logger'

import rawQuestionSets from '../assets/questions'

const questionSets = {}

Object.keys(rawQuestionSets).forEach(qs => {
  questionSets[qs] = JSON.parse(JSON.stringify(rawQuestionSets[qs])).questions
})

const useQuestions = (questionSet) => {
  const [indivQuestions, setIndivQuestions] = useState({})
  const [groupQuestions, setGroupQuestions] = useState({})
  const [groupDifficulty, setGroupDifficulty] = useState(0)

  useEffect(() => {
    // copy question set into this hook
    setIndivQuestions({ ...questionSets[questionSet].individual })
    setGroupQuestions({ ...questionSets[questionSet].group })
  }, [questionSet])

  useEffect(() => {
    // reload group questions if a difficulty has run out
    for (const difficulty of Object.keys(groupQuestions)) {
      if (groupQuestions[difficulty].length === 0) {
        setGroupQuestions(groupQuestions => {
          return {
            ...groupQuestions,
            [difficulty]: [...questionSets[questionSet].group[difficulty]]
          }
        })
      }
    }
  }, [groupQuestions, questionSet])

  useEffect(() => {
    // reload individual questions if a difficulty has run out
    for (const difficulty of Object.keys(indivQuestions)) {
      if (indivQuestions[difficulty].length === 0) {
        setIndivQuestions(indivQuestions => {
          return {
            ...indivQuestions,
            [difficulty]: [...questionSets[questionSet].individual[difficulty]]
          }
        })
      }
    }
  }, [indivQuestions, questionSet])

  /**
   * Chooses a question from {questions} and based on {difficulty} then removes the question from the set with {questionSetter}
   * @param {int} difficulty The difficulty to select based on
   * @param {Object} questions Set of questions sorted into difficulty arrays
   * @param {func} questionSetter Function to set {questions} in state
   * @returns {string} The chosen question
   */
  const getQuestion = (difficulty, questions, questionSetter) => {
    const questionPool = {}

    // add each valid difficulty set of questions to questionPool as a key, with the question's difficulty as the value (question: difficulty)
    for (const difficultySet of Object.keys(questions)) {
      if (difficulty >= difficultySet) {
        questions[difficultySet].forEach(q => { questionPool[q] = difficultySet })
      }
    }

    // chose a random question from questionPool
    const poolKeys = Object.keys(questionPool)
    const chosenQuestion = poolKeys[Math.floor(Math.random() * poolKeys.length)]
    const chosenQuestionDifficulty = questionPool[chosenQuestion]

    // remove the question from the entire pool of questions
    questionSetter(questions => {
      return {
        ...questions,
        [chosenQuestionDifficulty]: [
          ...questions[chosenQuestionDifficulty].filter(q => q !== chosenQuestion)
        ]
      }
    })

    return chosenQuestion
  }

  /**
   * Choose an individual question based on a player's score
   */
  const getIndividualQuestion = (difficulty) => {
    if (process.env.NODE_ENV !== 'production') logger.log(`Choosing individual question with difficulty ${difficulty}`, true)

    return getQuestion(difficulty, indivQuestions, setIndivQuestions)
  }

  /**
   * Choose a group question based on the group score
   */
  const getGroupQuestion = () => {
    if (process.env.NODE_ENV !== 'production') logger.log(`Choosing group question with difficulty ${groupDifficulty}`, true)

    // increment group difficulty
    setGroupDifficulty(gd => gd + 1)

    return getQuestion(groupDifficulty, groupQuestions, setGroupQuestions)
  }

  return { getIndividualQuestion, getGroupQuestion }
}

export default useQuestions
