import { useState } from 'react'

import useQuestions from '../../hooks/use-questions'
import { PlayerSpinner, QuestionCard } from '../../components'

const GamePlay = ({ players, choosePlayer, updatePlayer, chosenQuestionSet }) => {
  const { getIndividualQuestion, getGroupQuestion } = useQuestions(chosenQuestionSet)
  const [currentQuestion, setCurrentQuestion] = useState('')
  const [chosenPlayerIndex, setChosenPlayerIndex] = useState()
  const [challengePlayerName, setChallengePlayerName] = useState('')
  const [spinning, setSpinning] = useState(false)

  const onPlayerChosen = (chosenPlayerIndex) => {
    // ignore when this gets run the first time
    if (chosenPlayerIndex == null) return

    setSpinning(false)
    setChosenPlayerIndex(chosenPlayerIndex)

    // choose a secondary player to go along with the question (filling in {player})
    const validChallengePlayers = [...players.map(p => p.name)].filter((p, i) => i !== chosenPlayerIndex)
    setChallengePlayerName(validChallengePlayers[Math.floor(Math.random() * validChallengePlayers.length)])

    if (chosenPlayerIndex === -1) {
      setCurrentQuestion(getGroupQuestion())
    } else {
      setCurrentQuestion(getIndividualQuestion(players[chosenPlayerIndex].score))
    }
  }

  return (
    <>
      <PlayerSpinner
        playerNames={players.map(p => p.name)}
        choosePlayer={choosePlayer}
        onPlayerChosen={onPlayerChosen}
        onSpin={() => setSpinning(true)}
      />
      <QuestionCard
        playerName={chosenPlayerIndex >= 0 ? players[chosenPlayerIndex].name : 'Everyone'}
        challengePlayerName={challengePlayerName}
        question={currentQuestion}
        spinning={spinning}
      />
    </>
  )
}

export default GamePlay
